import React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PostWrapper from "../../components/postWrapper"
import PostList from "../../components/postList"
import PostSidebar from "../../components/postSidebarFilter"
import Pagination from "../../components/pagination"

export const query = graphql`
  query ($skip: Int, $limit: Int) {
    allSanityBlogTech(sort: {fields: publishDate, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          slug { current }
          category {
            title
            slug { current }
          }
          _rawThumbnail(resolveReferences: {maxDepth: 10})
          excerpt
        }
      }
    }
    sanityBlogSettings {
      heroTitleTech
      descriptionTech
      openGraphImageTech {
        asset {
          url
        }
      }
    }
  }
`

const TechIndex = (props) => {
  const settings = props.data.sanityBlogSettings
  const blogs = props.data.allSanityBlogTech.edges
  const { currentPage, blogTechPages } = props.pageContext
  const image = settings?.openGraphImageTech?.asset !== null ? settings?.openGraphImageTech?.asset.url : null

  return (
    <Layout theme="light">
      <SEO
        title={settings.heroTitleTech}
        description={settings.descriptionTech}
        image={image}
      />
      <Hero heroTitle={settings.heroTitleTech} theme="light" graph="radar" />
      <PostWrapper>
        <PostList items={blogs} theme="brandGamma" type="blog/product" />
        <PostSidebar theme="brandGamma" type="blogTech" />
      </PostWrapper>
      {blogTechPages > 1 && <Pagination base="blog/product/" currentPage={currentPage} totalPages={blogTechPages} />}
    </Layout>
  )
}

export default TechIndex
